import Glide from '@glidejs/glide';

$(function(){
  const glide = new Glide('.glide', {
    type: 'carousel',
    bound: true,
    startAt: 0,
    gap: 0,
    perView: 5,
    breakpoints: {
      1200: {
        perView: 4
      },
      1000: {
        perView: 4
      },
      800: {
        perView: 3
      },
      500: {
        perView: 1
      }
    }
  }).mount()
});
